var generic = generic || {};
var site = site || {};
var settings = Drupal.settings || {};
var fireDisplayTag = false;

site.offers = site.offers || {};
site.offers.welcome15 = site.offers.welcome15 || {};
site.userInfoCookie = site.userInfoCookie || {};
site.userInfoCookie.getValue =
  site.userInfoCookie.getValue ||
  function () {
    return '';
  };
site.elcEvents.addListener('tealium:displayWelcomeLoyaltyTag', function () {
  if (fireDisplayTag) {
    site.track.loyaltyTag('signup overlay');
  }
});
(function ($) {
  var overlayEmail;
  var templateData = {};
  var settingsLoyalty = typeof settings.loyalty === 'object' ? settings.loyalty : {};
  var loyaltyOfferPopupHeight = typeof settings.loyalty === 'object'
  && typeof settings.loyalty.offer_popup === 'object'
  && typeof settings.loyalty.offer_popup.height === 'object' ? settings.loyalty.offer_popup.height : {};

  Drupal.behaviors.ELB_welcome15 = {
    // debug config
    debug: false,
    // storage cookie name
    offerCookie: 'lyl_welcome_popup',
    // # of seconds before the overlay appears
    defaultTimeout: 3000,

    // template path config
    templates: {
      form: 'loyalty_welcome_offer',
      signup: 'loyalty_welcome_offer_signup',
      thanks: 'loyalty_welcome_offer_display'
    },

    pages: {
      mkt_non_lyl: 'marketing_lyl_join',
      mkt_anonymouse: 'marketing_lyl_signup',
      mkt_login: 'loyalty_welcome_offer_login',
      wlm_overlay: 'loyalty_popup_offer_form',
      wlm_signup: 'loyalty_popup_sign_up_forms'
    },

    // overlay config
    overlay: {
      pc: {
        className: settingsLoyalty.gdpr_sms_signup
          ? 'welcome15-overlay welcome15-overlay--txt-small'
          : 'welcome15-overlay',
        transition: 'none',
        width: '900px',
        height: '550px',
        opacity: '.8'
      },
      mobile: {
        className: settingsLoyalty.gdpr_sms_signup
          ? 'welcome15-overlay welcome15-overlay--txt-small'
          : 'welcome15-overlay',
        width: '95%',
        height: loyaltyOfferPopupHeight.mobile ? loyaltyOfferPopupHeight.mobile : '650px',
        transition: 'none',
        opacity: '.8'
      }
    },

    isStr: function (str) {
      return typeof str === 'string';
    },

    isFunc: function (func) {
      return typeof func === 'function';
    },

    isObj: function (mixed_var) {
      if (Object.prototype.toString.call(mixed_var) === '[object Array]') {
        return false;
      }

      return mixed_var !== null && typeof mixed_var === 'object';
    },

    clear: function () {
      $('.loyalty-loader').remove();
      $('#js-marketing__enrollment__signup').removeClass('hidden');
      $('#js-welcome-overlay__submit__button').removeClass('hidden');
      $('.welcome-overlay__submit__button').removeClass('hidden');
      $('.marketing__enrollment__email').val('');
      $('.welcome__overlay__enrollment__email').val('');
      $('.loyalty__form__error__message li').addClass('hidden');
      $('.marketing__enrollment__accept__terms__checkbox').attr('checked', false);
    },

    getTemplateContent: function (key, data) {
      var html;

      // Sanitize the incoming data
      data = data || false;

      // Get the html content of the template
      html = $("script.inline-template[path='" + key + "']").html();
      if (html.length < 1) {
        return $();
      }
      if (!$.isFunction(site.template.render)) {
        return $(html);
      }
      if (data === false) {
        // We should use site.template.render(html) instead just $(html), because $(html) displays conditonal mustache template markup
        return site.template.render(html);
      }

      // We have html and data, which means we should use Mustache to render the output
      // site.template.render(html, data) is wrapper for $(Mustache.render(html, data))
      return site.template.render(html, data);
    },

    // Open a colorbox window
    openWindow: function (content, callBacks, overlayObj) {
      var self = this,
        args,
        darg,
        overlay;

      overlay = self.isObj(overlayObj) ? overlayObj : self.overlay;
      args = { html: content };
      darg = $(window).width() < 1024 ? overlay.mobile : overlay.pc;
      $.extend(args, darg);

      if (self.isObj(callBacks)) {
        for (var k in callBacks) {
          var func = callBacks[k];

          if (self.isFunc(func)) {
            args[k] = func;
          }
        }
      }
      $.colorbox(args);
    },

    // Show the signup form
    showSignupForm: function (timeout) {
      var undef;
      var self = this;
      var delay = timeout !== undef ? timeout : self.defaultTimeout;
      var cboxArgs;
      var content;
      var useAltMarkUp;

      fireDisplayTag = true;
      setTimeout(function () {
        content = self.getTemplateContent(self.templates.form);
        // There is an input that creates a div that we can grab and specify an alternate layout class for i224733
        useAltMarkUp = $(content).find('#USE_ALT_LAYOUT').length;
        if (useAltMarkUp) {
          cboxArgs = site.client.isMobile ? self.overlay.mobile : self.overlay.pc;
          cboxArgs.className = 'welcome15-overlay alt_layout1';
          cboxArgs.fixed = true;
        }
        self.openWindow(content, {
          // Hide the content and overlay in onOpen, so we can fade it in initSignUpForm
          // This prevents "sliding" while the overridden css is loading, and also makes the overlay and content fade in simultaneously
          onOpen: function () {
            $('.welcome15-overlay #cboxContent,#cboxOverlay').css('visibility', 'hidden');
          },
          onComplete: site.offers.welcome15.initSignUpForm
        });
      }, delay);
    },

    /**
     * Helper function to determine if the welcome15 offer should appear on this page
     * @TODO: this will likely be expanded to be disabled on checkout and whatnot
     * @returns {boolean} whether welcome15 offer should appear on this page
     */
    showOfferOnPage: function () {
      var self = this,
        cookieName = self.offerCookie,
        hasCookie = $.cookie(cookieName),
        showOffer = false;

      if (!hasCookie) {
        // there is a div in the mustache that gets printed based on the disabled checbox input welcome15_offer_signup_v1
        var markUp = self.getTemplateContent(self.templates.form);
        var markUpDisabled = $(markUp).find('#DISABLE_POPOVER');

        // set the session cookie if the page is disabled, rather than checking every time
        if (markUpDisabled.length > 0) {
          $.cookie(cookieName, '1', {
            expires: 14,
            path: '/'
          });

          return false;
        }
      }

      if (self.debug || !hasCookie) {
        showOffer = true;
      }

      return showOffer;
    },

    enablePopup: function (content) {
      var daysCount = $(content).data('days-count');
      var startDate = new Date($(content).data('start-date'));

      if (typeof daysCount !== 'undefined' && startDate instanceof Date) {
        var now = new Date();
        var diffTime = Math.abs(now.getTime() - startDate.getTime());
        var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        if (daysCount >= diffDays) {
          return true;
        }
      }

      return false;
    },
    // Main function to get things kicked off
    attach: function (context) {
      var self = this,
        cookieName = self.offerCookie;
      var paramObj = {};
      var lylWelcomePopupHtml;

      $(document).on('user.loaded', function (event, val) {
        // landingpage form user nonloyalty
        $('#js-marketing__enrollment__join').on('click touchend', function () {
          var loyaltySignupPopup = typeof settingsLoyalty.signup_popup === 'object' ? settingsLoyalty.signup_popup : {};

          if (loyaltySignupPopup.redirect_only) {
            window.location =
              window.location.protocol + '//' + window.location.host + '/' + loyaltySignupPopup.online_endpoint;

            return false;
          }
          if (
            !$('.marketing__enrollment__accept__terms__checkbox', context).is(':checked') &&
            !settingsLoyalty.disable_lp_tc_checkbox
          ) {
            // if t&c checkbox is not present/not checked and disable_lp_tc_checkbox setting is enabled, skip this
            $('.loyalty__form__error__message', context).removeClass('hidden');

            return false;
          }
          $('.loyalty__form__error__message .required_accepted_privacy_policy', context).addClass('hidden');
          $('#js-marketing__enrollment__join', context)
            .after('<span class="loyalty-loader"></span>')
            .addClass('hidden');
          paramObj = {
            _SUBMIT: 'loyalty_join',
            LOYALTY_SOURCE: 'Site_Loyalty',
            LOYALTY_ACTIVE_FLAG: 1,
            ACCEPTED_LOYALTY_TERMS: 1,
            ACCEPTED_LOYALTY_TERMS_PRESENT: 1
          };

          var $acceptSmsPromotions = $('.js-marketing__enrollment__accept__sms_promotions', context);
          var $acceptEmailPromotions = $('.js-marketing__enrollment__accept__pc_promotions', context);

          if (settingsLoyalty.gdpr_sms_signup && $acceptSmsPromotions.is(':checked')) {
            var $mobileNumber = $('.js-marketing__enrollment__phone_number', context);
            var mobileNumberValid = site.offers.welcome15.validateForm('phone', $mobileNumber.val());

            if (mobileNumberValid) {
              paramObj.SMS_PROMOTIONS_PRESENT = '1';
              paramObj.SMS_PROMOTIONS = '1';
              paramObj.MOBILE_NUMBER = $mobileNumber.val();
            } else {
              $('#js-marketing__enrollment__join', context).removeClass('hidden');
              $('.loyalty-loader').remove();

              return false;
            }
          }
          if (settingsLoyalty.gdpr_sms_signup && $acceptEmailPromotions.is(':checked')) {
            paramObj.PC_EMAIL_PROMOTIONS_PRESENT = '1';
            paramObj.PC_EMAIL_PROMOTIONS = '1';
          }

          generic.jsonrpc.fetch({
            method: 'POST',
            params: [paramObj],
            onSuccess: function () {
              site.track.loyaltyTag('marketing enrollment');
              window.location.href = '/account/loyalty/index.tmpl';
            },
            onFailure: function (response) {
              // it seems that not_found.user_attribute.item. error code is not relly an error (check commits 6eb49a96656a5, e04bf9b408d5d and search for 'not_found.user_attribute.item.')
              if (
                settingsLoyalty.gdpr_sms_signup &&
                response.getError().data.messages[0].key === 'not_found.user_attribute.item.'
              ) {
                window.location.href = '/account/loyalty/index.tmpl';
              } else {
                $('.loyalty-loader').remove();
                $('#js-marketing__enrollment__join', context).removeClass('hidden');
              }
            }
          });
        });
        $('.marketing__enrollment__accept__terms__checkbox', context).change(function () {
          $('#js-marketing__enrollment__signup', context).toggleClass('disabled');
          $('#js-marketing__enrollment__join', context).toggleClass('disabled');
        });
        if (!val.signed_in) {
          lylWelcomePopupHtml = $("script[path='loyalty_welcome_offer']", context).html();
          if (self.showOfferOnPage() && self.enablePopup(lylWelcomePopupHtml)) {
            self.showSignupForm();
            // Create the cookie
            $.cookie(cookieName, '1', {
              expires: 14,
              path: '/'
            });
          }
        }
      });

      $(window).load(function () {
        var overlay, email_val, page;

        // landingpage form guest
        $('#js-marketing__enrollment__signup', context)
          .closest('form')
          .submit(function (e) {
            e.preventDefault();
            var loyaltySignupLandingPageForm =
              typeof settingsLoyalty.signup_popup === 'object' ? settingsLoyalty.signup_popup : {};
            var $accept__pc_promotions = $('.js-marketing__enrollment__accept__pc_promotions', context);
            var $accept__sms_promotions = $('.js-marketing__enrollment__accept__sms_promotions', context);
            var $mobile_number = $('.js-marketing__enrollment__phone_number', context);

            page = self.pages.mkt_anonymouse;
            email_val = $('.marketing__enrollment__email', context).val();
            overlayEmail = email_val;
            var phone_number_valid = true;

            if (
              loyaltySignupLandingPageForm.redirect_only &&
              site.offers.welcome15.validateForm('email', email_val, page)
            ) {
              site.offers.welcome15.redirectToRegistration({
                email: email_val,
                endpoint: loyaltySignupLandingPageForm.redirect_url,
                hideCta: true,
                context: context,
                form: '#' + e.target.id
              });

              return;
            }
            if (settingsLoyalty.gdpr_sms_signup) {
              if ($accept__pc_promotions.is(':checked')) {
                templateData.PC_EMAIL_PROMOTIONS = $accept__pc_promotions.val();
                templateData.PC_EMAIL_PROMOTIONS_PRESENT = '1';
              }
              if (
                $accept__sms_promotions.is(':checked') &&
                site.offers.welcome15.validateForm('phone', $mobile_number.val(), page)
              ) {
                templateData.SMS_PROMOTIONS = $accept__sms_promotions.val();
                templateData.SMS_PROMOTIONS_PRESENT = '1';
                templateData.MOBILE_NUMBER = $mobile_number.val();
              }
              phone_number_valid = site.offers.welcome15.validateForm(
                'phone',
                $('.marketing__enrollment__phone_number').val(),
                page
              );
            }
            if (phone_number_valid && site.offers.welcome15.validateForm('email', email_val, page)) {
              $('#js-marketing__enrollment__signup', context)
                .after('<span class="loyalty-loader"></span>')
                .addClass('hidden');
              site.offers.welcome15.initLoyaltyApi(e, page);
            }
          });

        $('.loyalty_join_signin_wrapper', context)
          .find('.signin-link')
          .click(function () {
            overlay = {
              pc: {
                className: 'loyalty-sign-in-overlay',
                transition: 'none',
                width: '400',
                height: '240'
              },
              mobile: {
                className: 'loyalty-sign-in-overlay',
                width: '90%',
                height: '240px',
                transition: 'none'
              }
            };
            site.offers.welcome15.renderOverlayPage(self.pages.mkt_login, overlay);
            $('section.social-login', context).remove();
            $('.login__registration-alt', context).remove();
          });
      });
    }
  };

  site.offers.welcome15 = {
    behavior: Drupal.behaviors.ELB_welcome15,

    getOverlayContent: function () {
      return $('#cboxLoadedContent');
    },

    renderOverlayPage: function (page, overlayObj) {
      var self = site.offers.welcome15;
      var content;

      if (page === self.behavior.pages.mkt_login) {
        content = $('section.login').html();
      } else {
        content = self.behavior.getTemplateContent(page, templateData);
      }
      self.behavior.openWindow(
        content,
        {
          // Hide the content and overlay in onOpen, so we can fade it in initSignUpForm
          // This prevents "sliding" while the overridden css is loading, and also makes the overlay and content fade in simultaneously
          onOpen: function () {
            $('.welcome15-overlay #cboxContent,#cboxOverlay').css('visibility', 'hidden');
          },
          onComplete: self.initSignUpForm
        },
        overlayObj
      );
    },

    validateForm: function (key, payload, page) {
      var self = site.offers.welcome15;
      var err = true;

      $('.loyalty__form__error__message li').addClass('hidden');
      switch (key) {
        case 'email':
          err = self.validateEmail(payload, page);
          break;
        case 'pass':
          err = self.validatePasssword(payload);
          break;
        case 'phone':
          err = self.validaPhoneNumber(payload, page);
          break;
      }
      if (!err) {
        return false;
      }

      return true;
    },

    validateEmail: function (payload, page) {
      var self = site.offers.welcome15;
      var terms_class = '.marketing__enrollment__accept__terms__checkbox';

      if (page === self.behavior.pages.wlm_overlay) {
        terms_class = '.js-welcome-overlay__checkbox__terms';
      }
      var reg = /^([A-Za-z0-9_\-.!#$^&*+=.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;

      if (!payload) {
        $('.loyalty__form__error__message .required_email_address').removeClass('hidden');

        return false;
      } else if (payload && reg.test(payload) === false) {
        $('.loyalty__form__error__message .invalid_email_address').removeClass('hidden');

        return false;
      } else if (!$(terms_class).is(':checked')) {
        // if t&c checkbox is not present/not checked and ...
        if (page === self.behavior.pages.wlm_overlay) {
          // ... if we re on overlay popup form, t&c checkbox must be checked to porceed
          if (settingsLoyalty.disable_lp_tc_checkbox) {
            // ... unless disable_popup_tc_checkbox is enabled
            return true;
          }
          $('.loyalty__form__error__message .required_accepted_privacy_policy').removeClass('hidden');

          return false;
        }
        if (!settingsLoyalty.disable_lp_tc_checkbox) {
          // ... if we re on lp and disable_lp_tc_checkbox setting is NOT ENABLED, t&c checkbox must be checked to porceed
          $('.loyalty__form__error__message .required_accepted_privacy_policy').removeClass('hidden');

          return false;
        }
      }

      return true;
    },

    validatePasssword: function (payload) {
      var reg = /^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*)(+=._-]{6,12}$/;

      if (!payload) {
        $('.loyalty__form__error__message .required_enter_password').removeClass('hidden');

        return false;
      } else if (payload && reg.test(payload) === false) {
        $('.loyalty__form__error__message .validation_password_criteria').removeClass('hidden');

        return false;
      }

      return true;
    },

    validateUser: function (userInfo) {
      var self = site.offers.welcome15;

      self.behavior.clear();
      if (userInfo.is_pro) {
        $('.loyalty__form__error__message .validation_error_pro_user').removeClass('hidden');

        return false;
      } else if (userInfo.is_loyalty_member) {
        $('.loyalty__form__error__message .validation_error_ext_lyl_user').removeClass('hidden');

        return false;
      } else if (userInfo.registered_user) {
        $('.loyalty__form__error__message .validation_error_ext_non_lyl_user').removeClass('hidden');

        return false;
      }

      return true;
    },

    validaPhoneNumber: function (payload) {
      var $checkbox = $('.js-marketing__enrollment__accept__sms_promotions').length
        ? $('.js-marketing__enrollment__accept__sms_promotions')
        : $('.js-welcome-overlay__checkbox__sms');
      var reg = /^([0-9][ -]*){8,12}\d$/;

      if (!payload && $checkbox.is(':checked')) {
        $('.loyalty__form__error__message .invalid_phone_number').removeClass('hidden');

        return false;
      }
      if (payload && reg.test(payload) === false && $checkbox.is(':checked')) {
        $('.loyalty__form__error__message .invalid_phone_number').removeClass('hidden');

        return false;
      }

      return true;
    },
    redirectToRegistration: function (payload) {
      var prependContent = '<input type="hidden" name="LOYALTY_EMAIL" value="' + payload.email + '"/>';

      if (payload.hideCta) {
        $('#js-marketing__enrollment__signup', payload.context)
          .after('<span class="loyalty-loader"></span>')
          .addClass('hidden');
      }
      $(payload.form, payload.context)
        .off('submit')
        .attr('action', window.location.origin + '/' + payload.endpoint)
        .attr('method', 'POST')
        .prepend(prependContent)
        .submit();

      return;
    },
    // Initialize the signup form
    initSignUpForm: function () {
      Drupal.behaviors.passwordTooltipIndicatorV1.attach();
      var self = site.offers.welcome15,
        overlay = self.getOverlayContent(),
        email_val,
        pass_val,
        page;

      // we set visibility to 0 onOpen, to avoid "sliding" while the css is loading
      // now we will:
      // 1) fade them both out
      $('.welcome15-overlay #cboxContent,#cboxOverlay').fadeOut(1, function () {
        // 2) set visibility on and fade in the overelay
        $('#cboxOverlay')
          .css('visibility', 'visible')
          .fadeIn(500, function () {
            // 3) set visibility and fade in the content (very quickly)
            $('.welcome15-overlay #cboxContent').css('visibility', 'visible').fadeIn(100);
          });
      });

      overlay.find('.js-welcome-overlay__checkbox__terms').change(function () {
        $('#js-welcome-overlay__submit__button').toggleClass('disabled');
      });

      overlay.find('.js-welcome-overlay__checkbox__sms').change(function () {
        $('.js-welcome-overlay__phone_number').toggleClass('hidden');
      });

      var $label_overlay = $('.js-welcome-overlay__label__overlay');

      if ($label_overlay.length) {
        overlay.find('.js-welcome-overlay__label').hover(
          function () {
            $label_overlay.addClass('visible');
          },
          function () {
            $label_overlay.removeClass('visible');
          }
        );
      }

      // hp popup 1 and 2 or landingpage popup 1
      overlay.find('form').submit(function (e) {
        e.preventDefault();
        var loyaltySignupPopup = typeof settingsLoyalty.signup_popup === 'object' ? settingsLoyalty.signup_popup : {};

        email_val = $('.welcome__overlay__enrollment__email').val();
        pass_val = $('.welcome-overlay__email .form-text__password').val();
        if (email_val) {
          overlayEmail = email_val;
        }
        if (settingsLoyalty.gdpr_sms_signup) {
          var $accept__pc_promotions = $('.js-welcome-overlay__checkbox__pc__promotions', e.target);
          var $accept__sms_promotions = $('.js-welcome-overlay__checkbox__sms', e.target);
          var $mobile_number = $('.js-welcome__overlay__enrollment__phone_number', e.target);

          if ($accept__pc_promotions.is(':checked')) {
            templateData.PC_EMAIL_PROMOTIONS = $accept__pc_promotions.val();
            templateData.PC_EMAIL_PROMOTIONS_PRESENT = '1';
          }
          if ($accept__sms_promotions.is(':checked') && self.validateForm('phone', $mobile_number.val(), page)) {
            templateData.SMS_PROMOTIONS = $accept__sms_promotions.val();
            templateData.SMS_PROMOTIONS_PRESENT = '1';
            templateData.MOBILE_NUMBER = $mobile_number.val();
          }
        }
        switch (e.target.id) {
          case 'loyalty_popup_offer_form':
            page = self.behavior.pages.wlm_overlay;
            if (!self.validateForm('phone', $('.welcome__overlay__enrollment__phone_number').val(), page)) {
              return false;
            }
            if (!self.validateForm('email', email_val, page)) {
              return false;
            }
            break;
          case 'loyalty_popup_sign_up_form':
            page = self.behavior.pages.wlm_signup;
            if (!self.validateForm('pass', pass_val, page)) {
              return false;
            }
            break;
        }
        if (loyaltySignupPopup.redirect_only) {
          site.offers.welcome15.redirectToRegistration({
            email: email_val,
            endpoint: loyaltySignupPopup.redirect_url,
            hideCta: false,
            form: '#' + e.target.id
          });
        } else {
          $('.welcome-overlay__submit__button').after('<span class="loyalty-loader"></span>').addClass('hidden');
          self.initLoyaltyApi(e, page);
        }
      });
    },

    initLoyaltyApi: function (e, page) {
      var self = site.offers.welcome15;
      var form = e.target;
      var fields = [
        '_SUBMIT',
        'COUNTRY_ID',
        'LANGUAGE_ID',
        'PC_EMAIL_PROMOTIONS',
        'PC_EMAIL_PROMOTIONS_PRESENT',
        'LOYALTY_ACTIVE_FLAG',
        'ACCEPTED_LOYALTY_TERMS',
        'redirect_or_text',
        'redirect',
        'PC_EMAIL_ADDRESS',
        'EMAIL_ADDRESS',
        'LOYALTY_EMAIL_PROMOTIONS',
        'LAST_SOURCE',
        'ORIGINAL_SOURCE',
        'RETURN_URL',
        'FIRST_NAME',
        'PASSWORD',
        'ACCEPTED_PRIVACY_POLICY'
      ];
      var paramObj = {};
      var field, undef;

      $.each(fields, function (i, name) {
        field = $('input[name="' + name + '"]', form);
        if (field !== undef && field.length > 0) {
          paramObj[name] = field.val();
          if (name === 'PC_EMAIL_ADDRESS' && overlayEmail) {
            paramObj[name] = overlayEmail;
          }
        }
      });

      if (settingsLoyalty.gdpr_sms_signup) {
        if (
          templateData.SMS_PROMOTIONS === '1' &&
          templateData.SMS_PROMOTIONS_PRESENT === '1' &&
          templateData.MOBILE_NUMBER
        ) {
          paramObj.SMS_PROMOTIONS = templateData.SMS_PROMOTIONS;
          paramObj.MOBILE_NUMBER = templateData.MOBILE_NUMBER;
        }
        if (typeof templateData === 'object' && templateData.PC_EMAIL_PROMOTIONS === '1') {
          paramObj.PC_EMAIL_PROMOTIONS = '1';
          paramObj.PC_EMAIL_PROMOTIONS_PRESENT = '1';
        } else {
          delete paramObj.PC_EMAIL_PROMOTIONS;
        }
      }

      generic.jsonrpc.fetch({
        method: 'rpc.form',
        params: [paramObj],
        onSuccess: function (jsonRpcResponse) {
          var response = jsonRpcResponse.getData(),
            userinfo = response.userinfo || {};

          overlayEmail = userinfo.email_address;
          if (page === self.behavior.pages.wlm_signup || page === self.behavior.pages.mkt_non_lyl) {
            var page_name = window.utag_data.page_name;

            if (page_name === 'loyalty_marketing_page') {
              site.track.loyaltyTag('marketing overlay');
            } else {
              site.track.loyaltyTag('enrollment overlay');
            }
            window.location.href = '/account/loyalty/index.tmpl';
          } else if (self.validateUser(userinfo)) {
            self.renderOverlayPage(self.behavior.templates.signup);
            self.behavior.clear();
          }
        },
        onFailure: function () {
          $('.loyalty-loader').remove();
          $('.welcome-overlay__submit__button').removeClass('hidden');
          $('.marketing__enrollment__button').removeClass('hidden');
        }
      });

      return false;
    }
  };
})(jQuery);
